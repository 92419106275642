import React from 'react'
import { getImagerForTops, getProfileUrl } from '../../Globals'
import { Link } from 'react-router-dom'

const CommunityTop = (props) => {
  return (
    <Link to={getProfileUrl(props.username)} className="col-span-12 md:col-span-4 max-h-16 h-16 flex bg-tertiary rounded-lg  border-r-[8px] border-r-primary" >
      <div className="w-[128px] overflow-hidden h-full bg-primary rounded-tl-lg rounded-bl-lg flex items-center justify-center">
        <img className='relative top-3' src={`https://nitro-imager.habbo.gt/?figure=${props.look}&size=m&direction=2&head_direction=2`} alt="" />
      </div>
      <div className="flex flex-col p-3">
        <p className='text-white text-lg'>{props.username}</p>
        <p className='text-xs text-white text-opacity-70'>{props.amount} {props.topName}</p>
      </div>
    </Link>
  )
}

export default CommunityTop
