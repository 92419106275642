import React, { useState } from 'react'
import { timeSince } from '../../Globals';
import { Link } from 'react-router-dom';

const VipUserItemView = (props) => {
    const [onMouse, setOnMouse] = useState(false);

    return (
        <Link to={"/profile/" + props.username} className="col-span-12 md:col-span-4 max-h-24 h-24 flex bg-tertiary rounded-lg" >
            <div className="w-[128px] overflow-hidden h-full bg-primary rounded-tl-lg rounded-bl-lg">
                <img className='relative -top-8' src={`https://nitro-imager.habbo.gt/?figure=${props.look}&size=l&direction=2&head_direction=2${onMouse ? "&action=wlk,wav,drk=1" : ""}`} alt="" />
            </div>
            <div className="flex flex-col p-3">
                <p className='text-white text-lg'>{props.username}</p>
                <p className='text-xs text-white text-opacity-70'>Danke für deine Unterstützung!</p>
            </div>
        </Link>
    )
}

export default VipUserItemView
