import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';

import LoadingView from '../loading/LoadingView';
import { LocalizeText } from '../../Globals';
import { VipsComposer } from '../../packets/VipsComposer';

import VipUserItemView from './VipUserItemView';

const VipsView = (props) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const [loading, setLoading] = useState(true);
    const [vips, setVips] = useState<any[]>([]);

    var isLoaded = false;
    useEffect(() => {
        if (isLoaded) return;
        isLoaded = true;

        document.title = "VIP HabboGT"
        sendMessage(JSON.stringify(new VipsComposer()))
    }, [])

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "usersVips" && message.data !== null) {
                setVips(message.data.vips)
                setLoading(false);
            }
        }
    }, [lastMessage, setMessageHistory]);

    const getRandomVips = () => {
        const shuffledVips = vips.sort(() => 0.5 - Math.random()); 
        return shuffledVips.slice(0, 6);
    };

    const randomVips = getRandomVips(); 

    return (
        <>
            {loading ? <LoadingView /> : (
                <div className="max-w-5xl mx-auto grid grid-cols-12 mt-5 animate__animated animate__fadeIn animate__slower gap-4 px-2 md:px-0">
                    <div className="col-span-12">
                        <div className="flex items-center bg-tertiary rounded-lg">
                            <div className="p-4 bg-primary flex items-center rounded-lg text-white gap-2 font-light text-sm">
                                <div className="cmCommentIcon"></div>
                                <span>Ankündigung</span>
                            </div>
                            <div className="p-3.5 text-sm text-white text-opacity-70">
                                Tritt unserem Discord bei, um nichts mehr zu verpassen!
                                <a href="https://discord.gg/UmZCFCjySq" target='__blank' className='ml-1'>
                                    discord.gg/UmZCFCjySq
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 flex flex-col">
                        <p className='text-xl font-bold text-white text-opacity-90 px-4'>Vips</p>
                        <div className="grid grid-cols-12 gap-4 mt-3">
                            {randomVips.map((x, index) => (
                                <VipUserItemView key={index} look={x.look} username={x.username} />
                            ))}
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                            <div className="cmPreGold"></div>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                            <div className="cmPreDia"></div>
                    </div>
                    <div className="col-span-12 md:col-span-4">
                            <div className="cmPreRoyal"></div>
                    </div>
                </div>
            )}
        </>
    )
}

export default VipsView;
