import React, { useState } from 'react'
import { timeSince } from '../../../Globals';
import { Link } from 'react-router-dom';

const CatalogItemUserView = (props) => {
    const [onMouse, setOnMouse] = useState(false);

    return (
        <Link to={"/profile/" + props.username} style={{textDecoration: "none"}}>
            <div className="h-16 overflow-hidden rounded bg-tertiary flex">
                <div className="avatarFigure max-w-[80px] w-full max-h-[130px] min-w-[64px] min-h-[110px]">
                    <img className='-mt-3' src={`https://nitro-imager.habbo.gt/?figure=${props.look}&direction=2&head_direction=2`}  alt="" />
                </div>
                <div className="flex justify-center flex-col -ml-3">
                    <span>{props.username}</span>
                    <span>Hat: {props.quantity}</span>
                </div>
            </div>
        </Link>
    )
}

export default CatalogItemUserView