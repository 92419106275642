import React, { useEffect, useState } from 'react'
import { NewUserBannerView } from './NewUserBannerView'
import PhotoHeaderView from './PhotoHeaderView'

interface BannerViewProps {
    tops: { [key: string]: string },
    community: { [key: string]: string }
    online: number
}

const randomIntFromInterval = (min: number, max: number) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const BannerView = (props: BannerViewProps) => {
    const [photos, setPhotos] = useState<any[]>([]);

    return (
        <div className='h-80 relative flex items-center' style={{ background: "url(https://i.imgur.com/a4VVjmO.png)", backgroundPositionY: "-87px" }}>
            <div className="absolute jeBlackedHeader"></div>
            <div className="max-w-7xl w-full mx-auto grid grid-cols-12 gap-4 relative">
                <div className="col-span-12 md:col-span-8 flex h-full justify-center md:justify-center flex-col gap-2">
                    <div className="header-texts-first font-extrabold text-center md:text-start text-2xl">
                        Kennst du schon das HabboGT?
                    </div>
                    <p className='"header-texts-second text-center hidden md:block text-white  md:text-start'>
                        Habbo Hotel ist eine virtuelle Welt, in der du jede menge Spaß haben kannst, dein Aussehen anpassen, Räume bauen, Rares sammeln und vieles mehr. Wir bieten dir viele einzigartige Features wie zum Beispiel unseren Battlepass oder das Gamecenter.
                    </p>
                    <p className='header-texts-third text-center text-white md:text-start'>
                    Im Hotel sind derzeit <b className='font-extrabold'>{props.online} HabboGT User</b> online!
                    </p>
                </div>
                <div className="col-span-12 hidden md:block md:col-span-4">
                    {Object.keys(props.community).length !== 0 &&
                        <PhotoHeaderView photos={JSON.parse(props.community.photos)} />
                    }
                </div>
            </div>
        </div>
    )
}
