import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { getArticleRoute, getNewsBackgroundHref, LocalizeText, timeSince } from '../../Globals'
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotificationManager } from 'react-notifications';
import { LoginComposer } from '../../packets/LoginComposer';

import LoadingView from '../loading/LoadingView';
import { NewsComposer } from '../../packets/NewsComposer';
interface WelcomeProps {
    texts: { [key: string]: string };
    account: any;
    online: number;
  }

const WelcomeView = (props: WelcomeProps) => {
    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
    const closeRef =  useRef<HTMLButtonElement>(null);
  
    const [username, setUsername] =  useState("");
    const [password, setPassword] = useState("");
  
    const navigate = useNavigate();

    useEffect(() => {
        if(props.account !== null) navigate("/me", { replace: true });
      }, [props]);
  
    const login = () => {
  
      if(username == "" || password == ""){
          NotificationManager.error(LocalizeText(props.texts, "login.error.spaces"), "Error");
          return;
      }
      
      sendMessage(JSON.stringify(new LoginComposer(username, password)))
    }


    ///


    const [loaded, setLoaded] = useState(false);
    const [news, setNews] = useState<any[]>([]);

    var isLoaded = false;
    useEffect(() => {
      if(isLoaded) return;
      isLoaded = true;
  
      sendMessage(JSON.stringify(new NewsComposer()))
    }, []);

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);
            if(message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
        }
      }, [lastMessage, setMessageHistory]);
    
      useEffect(() => { 
        if(news.length !== 0) setLoaded(true);
      }, [ news]);
  
    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);
            if(message.header === "loginResponse" && message.data != null){
                if(message.data.data === "Contraseña incorrecta!") NotificationManager.error(LocalizeText(props.texts, "login.error.wrongpassword"), "Error");
                else if(message.data.data === "Este usuario no existe!") NotificationManager.error(LocalizeText(props.texts, "login.error.usernotfound"), "Error");
                else if(message.data.data === "Esta cuenta esta baneada") NotificationManager.error(LocalizeText(props.texts, "login.error.banned"), "Error");
                else{
                    closeRef.current?.click()
                    NotificationManager.success(LocalizeText(props.texts, "login.success.session"), "OK");
                    window.localStorage.setItem("kubboch-sto", message.data.data);
                    navigate("/me", { replace: true });
                }
            }
        }
      }, [lastMessage, setMessageHistory]);

    return (
<>
            {!loaded ? <LoadingView /> : (
                <div className="flex flex-col">
                    <div className="cmMainHeader"></div>
                    <div className="max-w-5xl w-full mx-auto flex flex-col gap-12 p-3 md:p-0">
                        <div className="flex flex-col md:flex-row items-center gap-3">
                            <div className="cmLogo"></div>
                            <div className="flex flex-col justify-center items-center md:justify-normal md:items-start relative top-4">
                                <p className='text-2xl text-white'>Wilkommen im, <b>HabboGT!</b></p>
                                <div className="py-1 px-3 bg-secondary w-max gap-2 text-white rounded-full flex items-center justify-between relative">
                                    <p className='mr-10 text-white text-opacity-70'> {props.online} online!</p>
                                    <div className="hotelIcon absolute right-0"></div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 bg-tertiary rounded-lg w-full grid grid-cols-12 gap-6">
                            <div className="col-span-12 md:col-span-8 !h-96 p-14 bg-white rounded-lg cmPicture">
                                <div className=" flex flex-col text-white">
                                    <p className='text-2xl'>Wilkommen im, <b>HabboGT!</b></p>
                                    <p className='text-lg max-w-[480px]'>Schließe dich unserer lebhaften Community an und entdecke täglich neue Abenteuer im habbocm. Sei dabei und gestalte mit uns eine aufregende virtuelle Welt!</p>
                                    <Link to={"/register"}>
                                        <div className="p-2 md:p-0 md:px-20 mt-2 text-lg w-max bg-[#9aabb9] border-t-[6px] rounded-t-xl border-t-[#b4c8d9] border-b-[6px] rounded-b-xl border-b-[#82919e]">
                                            <b>Registrieren</b>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-12 md:col-span-4 flex flex-col gap-8">
                                <div className="p-2 flex gap-2 items-center bg-primary rounded-lg">
                                    <div className="hotelIcon"></div>
                                    <div className="flex flex-col">
                                        <p className='text-white text-lg'>Login</p>
                                        <p className='text-xs text-white text-opacity-50 relative -top-1'>Noch keinen Account? <Link to={"/register"} className="underline">Registriere dich</Link> jetzt
</p>

                                    </div>
                                </div>
                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-col gap-1">
                                        <p className='text-gray-300 text-sm'>Benutzername</p>
                                        <input type="text" onChange={(e) => setUsername(e.target.value)} className='cmInput' placeholder='Benutzername...' />
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <p className='text-gray-300 text-sm'>Passwort</p>
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} className='cmInput' placeholder='Passwort...' />
                                    </div>
                                    <div className="text-[11px] text-gray-300 text-opacity-70 -mt-3 flex justify-end">
                                        Hast du dein Passwort vergessen? Dann erstelle ein Ticket im Discord
                                    </div>
                                    <button onClick={() => login()} className="p-1 mt-0 text-xl text-center text-white w-full bg-[#01a41b] border-t-[10px] rounded-t-lg border-t-[#03c304] border-b-[10px] rounded-b-lg border-b-[#01981e]">
                                        Einchecken
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col gap-4 -mt-8">
                            <p className='text-xl font-bold text-white text-opacity-80 px-4'>News</p>
                            {news.length !== 0 &&
                                <div className="grid grid-cols-12 gap-2">
                                    {news[0] != null &&
                                        <Link to={getArticleRoute(news[0].id, news[0].title)} className='col-span-12 md:col-span-4 bg-tertiary rounded-lg p-3 flex flex-col gap-2'>
                                            <div className="flex items-center justify-between">
                                                <p className='text-white text-opacity-80'>{news[0].title}</p>
                                            </div>
                                            <div className="!h-28 cmPicture rounded-lg" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[0].header)})`}}></div>
                                            <p className='text-white text-opacity-60 text-xs'>{news[0].shortStory}</p>
                                            <div className="flex items-center justify-between text-white text-opacity-80 text-xs">
                                                <p>Weiter lesen</p>
                                                <p>von {news[0].author.username}</p>
                                            </div>
                                        </Link>
                                    }
                                    {news[1] != null &&
                                        <Link to={getArticleRoute(news[1].id, news[1].title)} className='col-span-12 md:col-span-4 bg-tertiary rounded-lg p-3 flex flex-col gap-2'>
                                            <div className="flex items-center justify-between">
                                                <p className='text-white text-opacity-80'>{news[1].title}</p>
                                            </div>
                                            <div className="!h-28 cmPicture rounded-lg" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[1].header)})`}}></div>
                                            <p className='text-white text-opacity-60 text-xs'>{news[1].shortStory}</p>
                                            <div className="flex items-center justify-between text-white text-opacity-80 text-xs">
                                                <p>Read All!</p>
                                                <p>by {news[1].author.username}</p>
                                            </div>
                                        </Link>
                                    }
                                    {news[2] != null &&
                                        <Link to={getArticleRoute(news[2].id, news[2].title)} className='col-span-12 md:col-span-4 bg-tertiary rounded-lg p-3 flex flex-col gap-2'>
                                            <div className="flex items-center justify-between">
                                                <p className='text-white text-opacity-80'>{news[2].title}</p>
                                            </div>
                                            <div className="!h-28 cmPicture rounded-lg" style={{ backgroundImage: `url(${getNewsBackgroundHref(news[2].header)})`}} ></div>
                                            <p className='text-white text-opacity-60 text-xs'>{news[2].shortStory}</p>
                                            <div className="flex items-center justify-between text-white text-opacity-80 text-xs">
                                                <p>Read All!</p>
                                                <p>by {news[2].author.username}</p>
                                            </div>
                                        </Link>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full p-12 bg-tertiary mt-12">
                        <div className="max-w-5xl w-full mx-auto flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <div className="cmHabboIcon"></div>
                                <div className="flex flex-col text-sm">
                                    <a href="" className='text-white text-opacity-60'><b>Discord</b></a>
                                    <p className='text-white text-opacity-60'>© 2024 by HabboGT - Designed by <b>jw7speed</b>, developed by <b>Lariesse & Mori</b></p>
                                    <p className='text-white text-opacity-60'>All rights go to their respective owner(s). We are not endorsed, affiliated by Sulake Corporation.</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-4 justify-center text-white">
                                <a href="">
                                    <i className='fa-brands fa-discord text-3xl'></i>
                                </a>
                                <a href="">
                                    <i className='fa-brands fa-tiktok text-3xl'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default WelcomeView
