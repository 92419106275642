import React, { useEffect, useState } from 'react';
import { NewUserBannerView } from './NewUserBannerView';
import PhotoHeaderView from './PhotoHeaderView';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface TopHeaderView {
    account: any;
    online: number;
}

export const TopHeaderView = (props: TopHeaderView) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [clientInit, setclientInit] = useState(false);
    const [visibility, setVisibility] = useState<DocumentVisibilityState>("visible");

    useEffect(() => {
        if (location.pathname === "/play") {
            setclientInit(true);
            setVisibility("visible");
        } else {
            setVisibility("hidden");
        }
    }, [location]);

    const getSrcIframe = (sso) => "https://nitro.habbo.gt/index.html?sso=" + sso;

    return (
        <div className="flex flex-col">
            <div className="cmMainHeaders h-auto md:h-[180px] flex justify-center items-center">
                <div className="max-w-5xl w-full mx-auto flex flex-col md:flex-row justify-between items-center p-3 md:p-0">
                    <div className="flex flex-col md:flex-row items-center gap-3">
                        <div className="cmLogo"></div>
                        <div className="flex flex-col justify-center items-center md:justify-normal md:items-start relative top-4">
                            <p className='text-2xl text-white'>Wilkommen, <b>{props.account.username}!</b></p>
                            <div className="py-1 px-3 bg-secondary w-max gap-2 text-white rounded-full flex items-center justify-between relative">
                                <p className='mr-10 text-white text-opacity-70'>{props.online} online!</p>
                                <div className="hotelIcon absolute right-0"></div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="flex">
                    <Link to={"/settings"}>
    <div className="py-2 px-3 flex justify-center items-center text-white bg-[#2e2e2e] border-t-[6px] rounded-t-lg border-t-[#2e2e2e] border-b-[6px] rounded-b-lg border-b-[#2e2e2e] hover:bg-[#2e2e2e] transition mr-4"> 
        
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 15.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM19.937 12.065c.05-.364.063-.735.063-1.065s-.013-.701-.063-1.065l2.1-1.65a.548.548 0 00.12-.69l-2-3.464a.54.54 0 00-.663-.22l-2.48.995a7.972 7.972 0 00-1.845-1.065l-.375-2.64A.552.552 0 0014.2 2h-4.4a.552.552 0 00-.547.47l-.375 2.64a7.972 7.972 0 00-1.845 1.065L4.553 5.18a.548.548 0 00-.663.22l-2 3.464a.54.54 0 00.12.69l2.1 1.65c-.05.364-.063.735-.063 1.065s.013.701.063 1.065l-2.1 1.65a.548.548 0 00-.12.69l2 3.464c.15.26.485.356.663.22l2.48-.995a7.972 7.972 0 001.845 1.065l.375 2.64c.038.274.273.47.547.47h4.4c.274 0 .509-.196.547-.47l.375-2.64a7.972 7.972 0 001.845-1.065l2.48.995c.278.135.513.038.663-.22l2-3.464a.54.54 0 00-.12-.69l-2.1-1.65z"
            />
        </svg>
    </div>
</Link>

                        <Link to={"/play"}>
                            <div className="py-0 px-24 mt-0 text-xl text-center text-white w-full bg-[#01a41b] border-t-[10px] rounded-t-lg border-t-[#03c304] border-b-[10px] rounded-b-lg border-b-[#01981e]">
                                Client
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            {props.account !== null && clientInit && (
                <>
                    {visibility === "visible" && <Link className='back-button btn-sm text-center' to="/me"></Link>}

                    <div style={{overflow: "hidden"}}>
                        <iframe allow="
                            accelerometer *; 
                            ambient-light-sensor *; 
                            autoplay *; 
                            camera *; 
                            clipboard-read *; 
                            clipboard-write *; 
                            encrypted-media *; 
                            fullscreen *; 
                            geolocation *; 
                            gyroscope *; 
                            magnetometer *; 
                            microphone *; 
                            midi *; 
                            payment *; 
                            picture-in-picture *; 
                            screen-wake-lock *; 
                            speaker *; 
                            sync-xhr *; 
                            usb *; 
                            web-share *; 
                            vibrate *; 
                            vr *"
                        className='animate__animated animate__fadeIn animate__slower' 
                        src={getSrcIframe(window.localStorage.getItem("kubboch-sto"))} 
                        style={{ 
                            position: 'absolute', 
                            top: 0, 
                            left: 0, 
                            right: 0, 
                            bottom: 0, 
                            width: "100%", 
                            height: "100%", 
                            border: "none", 
                            margin: 0, 
                            padding: 0, 
                            overflow: "hidden !important", 
                            zIndex: 999999, 
                            visibility: visibility
                        }}
                        >
                        </iframe>
                    </div>
                </>
            )}
        </div>
    );
}
