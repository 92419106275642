import React, { useEffect, useState } from 'react';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { useNavigate, useParams, Link } from 'react-router-dom';



import { timeSince, getImagerForTops, getNewsBackgroundHref, getArticleRoute, getNewsImagerAuthorLook, getProfileUrl, LocalizeText } from '../../Globals';

import LoadingView from '../loading/LoadingView';
import { ArticleComposer } from '../../packets/ArticleComposer';
import { NewsComposer } from '../../packets/NewsComposer';
import { ArticleCommentComposer } from '../../packets/ArticleCommentComposer';
import { DeleteArticleCommentComposer } from '../../packets/DeleteArticleCommentComposer';
import { NotificationManager } from 'react-notifications';

const ArticleView = (props, { match }) => {
  const [messageHistory, setMessageHistory] = useState([]);
  const [socketUrl, setSocketUrl] = useState("wss://habbocm.hanarchy.net/e");
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });
  const navigate = useNavigate();
  const params = useParams();

  const [article, setArticle] = useState<any>({});
  const [news, setNews] = useState<any[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");

  const getArticleIdByParam = (param) => {
    if (param === null) return 0;
    else return param;
  }

  var isLoaded = false;
  useEffect(() => {
    if (isLoaded) return;
    isLoaded = true;

    if (params === null || params.id === null) navigate("/articles", { replace: true });
    document.title = params.name?.replace("-", " ") + " | " + LocalizeText(props.texts, 'global.hotelname')
    sendMessage(JSON.stringify(new ArticleComposer(getArticleIdByParam(params.id))))
    sendMessage(JSON.stringify(new NewsComposer()))
  }, [])

  useEffect(() => {
    if (lastMessage !== null) {
      var message = JSON.parse(lastMessage.data);

      if (message.header === "newdata" && message.data !== null) {
        if (message.data.new === null) navigate("/articles", { replace: true });
        setArticle(JSON.parse(message.data.new));
        setComments(JSON.parse(message.data.comments));
      }
      if (message.header === "newsList" && message.data !== null) setNews(JSON.parse(message.data.data));
      if (message.header === "CommentResponse" && message.data !== null) {
        if (message.data.data === "Esta noticia no existe") NotificationManager.error(LocalizeText(props.texts, 'article.no.found'), "Error")
        else if (message.data.data === "Error") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else if (message.data.data === "Comentario no valido") NotificationManager.error(LocalizeText(props.texts, 'article.comment.invalid'), "Error")
        else if (message.data.data === "No intentes hacer publicidad!") NotificationManager.error(LocalizeText(props.texts, 'article.comment.publi'), "Error")
        else if (message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else NotificationManager.success(LocalizeText(props.texts, 'article.comment.success'), "OK")
      }
      if (message.header === "removeCommentResponse" && message.data !== null) {
        if (message.data.data === "Esta noticia no existe") NotificationManager.error(LocalizeText(props.texts, 'article.no.found'), "Error")
        else if (message.data.data === "Algo salio mal") NotificationManager.error(LocalizeText(props.texts, 'article.error'), "Error")
        else NotificationManager.success(LocalizeText(props.texts, 'article.comment.remove'), "OK")
      }
    }
  }, [lastMessage, setMessageHistory]);

  useEffect(() => {
    if (news.length !== 0 && article !== null) {
      setLoading(false);
    }
  }, [article, news])

  const changeNotice = (id) => {
    setArticle(null);
    setLoading(true);
    sendMessage(JSON.stringify(new ArticleComposer(id)))
  }

  const createComment = () => {
    if (comment === "") {
      NotificationManager.error(LocalizeText(props.texts, 'article.comment.notext'), "Error");
      return;
    }

    sendMessage(JSON.stringify(new ArticleCommentComposer(article.id, comment)));
    sendMessage(JSON.stringify(new ArticleComposer(article.id)))
  }

  const deleteComment = (id) => {
    sendMessage(JSON.stringify(new DeleteArticleCommentComposer(article.id, id)))
    sendMessage(JSON.stringify(new ArticleComposer(article.id)))
  }

  return (
    <>
      {loading ? <LoadingView /> : (
        <>
          {article !== null &&
            <div className='max-w-5xl w-full gap-4 mx-auto grid grid-cols-12 animate__animated animate__fadeIn animate__slower mt-5 px-2 md:px-0'>
              <div className="col-span-12">
                <div className="flex items-center bg-tertiary rounded-lg">
                  <div className="p-4 bg-primary flex items-center rounded-lg text-white gap-2 font-light text-sm">
                    <div className="cmCommentIcon"></div>
                    <span>Ankündigung</span>
                  </div>
                  <div className="p-3.5 text-sm text-white text-opacity-70">
                    Tritt unserem Discord bei, um nichts zu verpassen!
                    <a href="https://discord.gg/UmZCFCjySq" target='__blank' className='ml-1'>
                      discord.gg/UmZCFCjySq
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-span-12 flex flex-col gap-3">
                <p className='text-xl font-bold text-white text-opacity-90 px-4'>News</p>
                <div className="p-2 bg-tertiary rounded-lg flex flex-col">
                  <div className="p-3 flex flex-col gap-2 justify-center cmBPicture !h-36 rounded-lg">
                    <div className="flex flex-col pr-20 pl-3 justify-star py-2 w-max rounded-lg bg-tertiary">
                      <p className='text-white text-opacity-80'>{article.title}</p>
                      <p className='text-xs text-white text-opacity-60'>{article.title}</p>
                    </div>
                    <div className="pr-3 pl-0.5 bg-tertiary flex items-center w-max gap-1 text-xs text-white text-opacity-80 rounded-lg">
                      <div className="p-1 bg-secondary rounded-full">
                        <img src={`https://www.habbo.com/habbo-imaging/avatarimage?figure=${article.author.look}&headonly=1&size=s`} alt="" />
                      </div>
                      <span>von {article.author.username}</span>
                    </div>
                  </div>
                  <div id="articleContent" style={{ whiteSpace: "pre-wrap" }} className="text-white text-opacity-80 p-3" dangerouslySetInnerHTML={{ __html: article.fullStory }} />
                </div>
              </div>
            </div>
          }
        </>
      )}
    </>
  )
}

export default ArticleView
