import React, { useEffect, useState } from 'react'

import { BATTLEPASS_RARE, BONZEN_RARE, CASH_RARE, CASINO_RARE, CATEGORY_CRACKABLE, CATEGORY_CUSTOM, CATEGORY_LTD, CATEGORY_RARE, EVENTPUNKTE_RARE, GIVEAWAY_RARE, SCHWARZMARKT_RARE, SONSTIGE_RARE, WOCHENRARE } from './Category';
import CatalogItemView from './views/CatalogItemView';
import useWebSocket from 'react-use-websocket';
import { NotificationManager } from 'react-notifications';

const CatalogView = (props: any) => {

    const [items, setItems] = useState<any[]>([]);
    const [category, setCategory] = useState(CATEGORY_RARE);
    const [search, setSearch] = useState("");

    const [messageHistory, setMessageHistory] = useState([]);
    const [socketUrl, setSocketUrl] = useState("wss://ws.habbo.one:2087");
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, { share: true });

    useEffect(() => {
        if (lastMessage !== null) {
            var message = JSON.parse(lastMessage.data);

            if (message.header === "updateCatalogPriceResponse" && message.data !== null) {
                const status: string = message.data.data;

                if (status === "Ok") {
                    NotificationManager.success("Aktualisierter Preis", "Ok");
                    fetchData();
                }
                else if (status === "Error") NotificationManager.error("Fehler beim Aktualisieren des Preises", "Error");
            }
        }
    }, [lastMessage, setMessageHistory]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://youtube.habbo.gt/Rare/get');
            const jsonData = await response.json();
            setItems(jsonData);
        } catch (error) { }
    };



    return (
        <div className="max-w-5xl mx-auto grid grid-cols-12 mt-5 gap-4 animate__animated animate__fadeIn animate__slower">
                <div className="col-span-12">
                    <div className="flex items-center bg-tertiary rounded-lg">
                        <div className="p-4 bg-primary flex items-center rounded-lg text-white gap-2 font-light text-sm">
                            <div className="cmCommentIcon"></div>
                            <span>Ankündigung</span>
                        </div>
                        <div className="p-3.5 text-sm text-white text-opacity-70">
                            Tritt unserem Discord bei, um nichts mehr zu verpassen!
                            <a href="https://discord.gg/UmZCFCjySq" target='__blank' className='ml-1'>
                                discord.gg/UmZCFCjySq
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-span-12">
                </div>
                <div className='col-span-12 gap-2 grid grid-cols-12'>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(CATEGORY_RARE)}>Rares</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(CATEGORY_CUSTOM)}>Custom</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(CATEGORY_CRACKABLE)}>Crackables</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(SCHWARZMARKT_RARE)}>Schwarzmarkt Rare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(CASINO_RARE)}>Casino Rare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(GIVEAWAY_RARE)}>Giveaway Rare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(WOCHENRARE)}>Wochenrare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(EVENTPUNKTE_RARE)}>Eventpunkte Rare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(BATTLEPASS_RARE)}>Battlepass Rare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(SONSTIGE_RARE)}>Sonstige Rare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(BONZEN_RARE)}>Bonzen Rare</button>
                    </div>
                    <div className='col-span-6 md:col-span-3'>
                        <button className='bg-tertiary p-2 rounded-lg w-full text-white font-light hover:bg-opacity-70 transition-all' onClick={() => setCategory(CASH_RARE)}>Cash Rare</button>
                    </div>


                </div>
            <div className='col-span-12 w-full grid grid-cols-12 gap-4 mt-4'>
                {items.length != 0 && search.length == 0 ?
                    items.filter(item => item.category === category).map(i =>
                        <CatalogItemView item={i} canEdit={props.account != null ? props.account.editPrice : false} />
                    )
                    :
                    items.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(i =>
                        <CatalogItemView item={i} canEdit={props.account != null ? props.account.editPrice : false} />
                    )
                }
            </div>
        </div>
    )
}

export default CatalogView